<template>
<div>
	<div class="ve-feedback-menu" @click="show = !show">{{ __('ve-feedback-menu') }}</div>
	<div v-show="show" class="ve-feedback-overlay-container">
		<div class="ve-feedback-overlay-background" v-on:click.self="show = !show"></div>
		<div class="ve-feedback-overlay-pos">
			<div class="ve-feedback-overlay-content">
				
				<div>
					<h3>{{ __("ve-feedback-menu") }}</h3>
				</div>
				<div>
					<div>{{ __('ve-feedback-specify-action') }}</div>
					<ve-select	v-model="action"
								:options="[
									this.__('ve-feedback-send-feedback'),
									this.__('ve-feedback-send-issue'),
									this.__('ve-feedback-answer-customer-survey'),
									this.__('ve-feedback-feedback-load-issue'),
								]"></ve-select>
				</div>

				<div v-if="action == __('ve-feedback-send-feedback')">
					<div class="mt-4">{{ __('ve-feedback-feedback-subject') }}</div>
					<ve-input v-model="feedback_subject"></ve-input>

					<div class="mt-2">{{ __('ve-feedback-feedback-body') }}</div>
					<ve-textarea v-model="feedback_body"></ve-textarea>
					<div>
						<!-- <button type="button"
								class="btn btn-rounded btn-secondary mt-2"
								@click="copy_body"
								:disabled="false">
							{{ __("ve-feedback-feedback-copy") }}
						</button> -->
						<button type="button"
								class="btn btn-rounded btn-secondary mt-2"
								@click="download({downloadLocally:true})"
								:disabled="false">
							{{ __("ve-feedback-feedback-download") }}
						</button>
					</div>

					<div>
						<button type="button"
								class="btn btn-rounded btn-primary mt-2"
								@click="send_poc_feedback"
								:disabled="false">
							{{ __("ve-feedback-feedback-send") }}
						</button>
					</div>
				</div>

				<div v-if="action == __('ve-feedback-feedback-load-issue')">

					<input type="file"
						ref="loadFile"
						accept="application/zip"
						@change="load"
						hidden/>

					<button type="button"
							class="btn btn-rounded btn-primary mt-2"
							@click="$refs.loadFile.click()"
							:disabled="false">
						{{ __("ve-feedback-feedback-load-file") }}
					</button>

					<div v-if="loaded_feedback_subject">
						<div class="mt-4">{{ __('ve-feedback-feedback-subject') }}</div>
						<ve-input v-model="loaded_feedback_subject"></ve-input>
					</div>

					<div v-if="loaded_feedback_body">
						<div class="mt-2">{{ __('ve-feedback-feedback-body') }}</div>
						<ve-textarea v-model="loaded_feedback_body"></ve-textarea>
					</div>

					<div v-if="feedback_img_url" class="mt-4">
						<div>{{ __("ve-feedback-loaded-image") }}</div>
						<div class="ve-feedback-loaded-image-container">
							<img class="ve-feedback-loaded-image" :src="feedback_img_url" alt="">
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
</template>
<script>
import { auth, envelopes, remote } from '@verified/libvf'
import jszip from "jszip"
import html2canvas from "html2canvas"

export default {
	name: "ve-feedback",
	props: {
		saveExclude: {
			type: RegExp,
			default: /^$/
		},
	},
	data() {
		return {
			show: false,
			action: '',
			actions: [],
			feedback_subject: 'DEV TEST - Mall feedback',
			feedback_body: 'DEV TEST - Flytta första knappen lite uppåt.',
			feedback_body_append: '',
			feedback_img_url: '',
			loaded_feedback_subject: '',
			loaded_feedback_body: '',
		}
	},
	methods: {
		async onCreated() {
			// Translations
			this.mergeLocale({
				've-feedback-menu': {
					sv_SE: 'Återkoppla',
					en_EN: 'Feedback',
				},
				've-feedback-specify-action': {
					sv_SE: 'Ange åtgärd',
					en_EN: 'Specify action',
				},
				've-feedback-send-feedback': {
					sv_SE: 'Skicka feedback',
					en_EN: 'Send feedback',
				},
				've-feedback-send-issue': {
					sv_SE: 'Skicka felrapport',
					en_EN: 'Send issue',
				},
				've-feedback-answer-customer-survey': {
					sv_SE: 'Svara på kund-enkät',
					en_EN: 'Fill in customer-survey',
				},
				've-feedback-feedback-subject': {
					sv_SE: 'Ämnesrad',
					en_EN: 'Subject',
				},
				've-feedback-feedback-body': {
					sv_SE: 'Innehåll',
					en_EN: 'Body',
				},
				've-feedback-feedback-copy': {
					sv_SE: 'Kopiera',
					en_EN: 'Copy',
				},
				've-feedback-feedback-download': {
					sv_SE: 'Ladda ner',
					en_EN: 'Download',
				},
				've-feedback-feedback-copied': {
					sv_SE: 'Kopierad',
					en_EN: 'Copied',
				},
				've-feedback-feedback-send': {
					sv_SE: 'Skicka',
					en_EN: 'Send',
				},
				've-feedback-feedback-load-issue': {
					sv_SE: 'Ladda in felrapport',
					en_EN: 'Load issue',
				},
				've-feedback-feedback-load-file': {
					sv_SE: 'Ladda fil',
					en_EN: 'Load file',
				},
				've-feedback-loaded-image': {
					sv_SE: 'Uppladdade bild',
					en_EN: 'Uploaded picture',
				},
				
			})		
		},
		onMounted() {},
		copy_body() {
			this.update_body_append()
			let text = (this.feedback_body+'\n\n'+this.feedback_body_append).replace(/\\n/g,'\r\n')
			var textArea = document.createElement("textarea")
			textArea.value = text
			document.body.appendChild(textArea)
			textArea.focus()
			textArea.select()
			try {
				var successful = document.execCommand('copy')
				var msg = successful ? 'successful' : 'unsuccessful'
				console.log('Copying text command was ' + msg)
			} catch (err) {
				console.log('Oops, unable to copy')
			}
			document.body.removeChild(textArea)
		},
		async update_body_append() {
			let authData = ''
			if (auth) {
				if(auth.tokenData && auth.tokenData.sub) {
					authData += [
						auth.tokenData.sub.email ? `--User email ${auth.tokenData.sub.email}\n`: '',
						auth.tokenData.sub.givenName ? `--User givenName ${auth.tokenData.sub.givenName}\n`: '',
						auth.tokenData.sub.familyName ? `--User familyName ${auth.tokenData.sub.familyName}\n`: '',
					].join('')
				}
				if(auth.namespace) {
					authData += `--Company-ns ${auth.namespace}\n`
				}
			}
			this.feedback_body_append = [
				authData,
				`--Url ${location.href}\n`,
				`--UserAgent ${navigator.userAgent}\n`,
				`--WindowSize ${window.innerWidth}X${window.outerHeight}`,
				window.screen ? `\n--ScreenSize ${window.screen.width}X${window.screen.height}`: ''
			].join('')
		},
		async send_feedback() {
			this.update_body_append()
			let mailto = `jorge.guerra+support@verified.eu`
			let subject = this.feedback_subject
			let body = (this.feedback_body+'\n<br>\n'+this.feedback_body_append).replace(/\\n/g,'\r\n')
			let cc = `jorge.guerra+cc@verified.eu`
			let bcc = `jorge.guerra+bcc@verified.eu`
			location.href = `mailto:${mailto}?subject=${subject}&body=${body}&cc=${cc}&bcc=${bcc}`
		},
		async send_poc_feedback() {
			let download = await this.download()
			let res = await remote.raw({
				url: '/api/rnd/ticket',
				method: 'POST',
				data: download,
				withCredentials: "same-origin",
				authorization: 'JWT '+auth.token,
				'x-namespace': auth.namespace,
				'content-type': 'application/zip',
				accept: 'application/json',
			})
			console.log(res)
		},
		async download(args) {
			var args = args || {}
			let downloadLocally = args.downloadLocally
			const target = this.$parent
			let template_data = {}
			// TODO support recursive like ud.username
			const saveExclude = this.saveExclude
			for(let td_i in target._data) {
				let td = target._data[td_i]
				if(!saveExclude.test(td_i)) {
					template_data[td_i] = td
				}
			}
			this.update_body_append()
			const json = {
				subject: this.feedback_subject,
				body: (this.feedback_body+'\n'+this.feedback_body_append).replace(/\\n/g,'\r\n'),
				template_data: JSON.parse(JSON.stringify(template_data)),
				tokenData: auth.tokenData,
				namespace: auth.namespace,
				url: location.href,
				useragent: navigator.userAgent
			}
			let zip = new jszip()
			zip.file('template.json', JSON.stringify(json, null, 2))
			if(true) {
				this.show = false
				await this.raf()
				const canvas = await html2canvas(document.querySelector("body"))
				const imgDataUrl = canvas.toDataURL()
				const imgUIntArray = this.base64ToArrayBuffer(imgDataUrl.replace(/.*,/,''))
				zip.file('./screen.png', new Uint8Array(imgUIntArray), { binary: true })
				this.show = true
			}
			if(downloadLocally) {
				return zip.generateAsync({type:"base64"}).then(function (base64) {
					const dataUrl = "data:application/zip;base64," + base64
					let a = document.createElement('a')
					a.href = dataUrl
					a.download = 'feedback.zip'
					a.click()
				})
			} else {
				return zip.generateAsync({type:"blob"}).then(function (blob) {
					return blob
				})
			}
		},
		async load() {
			const self = this
			const files = this.$refs.loadFile.files
			const target = this.$parent
			if(files.length) {
				jszip.loadAsync(files[0]).then(function (zip) {
					if(zip && zip.files) {
						let template_json = zip.files['template.json']
						if(template_json) {
							template_json.async('text').then((text)=>{
								let json = {}
								try { json = JSON.parse(text) }
								catch (err) {}
								if(json.template_data) {
									for(let li_i in json.template_data) {
										let li = json.template_data[li_i]
										target[li_i] = li
									}
								}
								self.loaded_feedback_subject = json.subject || ''
								self.loaded_feedback_body = json.body || ''
								console.log(json)
							})
						}
						let screen_png = zip.files['./screen.png']
						if(screen_png) {
							screen_png.async('base64').then((base64)=> {
								self.feedback_img_url = "data:image/png;base64," + base64
							})
						}
					}
				})
			}
		},
		async raf() { requestAnimationFrame(()=>{ return }) },
		base64ToArrayBuffer(base64) {
			var binary_string = window.atob(base64);
			var len = binary_string.length;
			var bytes = new Uint8Array(len);
			for (var i = 0; i < len; i++) {
				bytes[i] = binary_string.charCodeAt(i);
			}
			return bytes.buffer;
		}
	},
	watch: {},
	created() { this.onCreated() },
	mounted() { this.onMounted() },
}
</script>
<style lang="scss" scoped>
.ve-feedback-menu{
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 9000;
	cursor: pointer;
	background: #004daa;
	color: white;
	border: 1px solid #000;
	padding: 10px 15px;
}
.ve-feedback-overlay-container{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9001;
}
.ve-feedback-overlay-background {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0.5;
	background: black;
}
.ve-feedback-overlay-pos {
	position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	min-width: 90%;
	min-height: 90%;
	overflow: auto;
}
.ve-feedback-overlay-content {
	background: #eee;
	padding: 20px;
	border-radius: 4px;
	max-height: 100%;
}
.ve-feedback-loaded-image {
	max-width: 100%;
}
.ve-feedback-loaded-image-container{
	overflow: auto;
    max-height: 400px;
}
</style>