import axios from 'axios'
import { auth, envelopes, bisnode, remote } from '@verified/libvf'
// import { auth, envelopes, bisnode, remote } from '../../../../libvf'
// import veform from '@verified/veform'
// import veform from '../../../../veform'
import { PDFDocument, StandardFonts, PDFAcroText, PDFAcroCheckBox, PDFAcroRadioButton, PDFAcroComboBox, PDFAcroListBox } from 'pdf-lib'
import docxtemplater from "docxtemplater"
import jszip from "jszip"

export default {
	name: "form-group-signing",
	props: {
		sheetname: {
			type: String
		},
		role: {
			type: String
		},
		lang: {
			type: String
		},
		userdata: {
			type: Object,
			default: function () { return {} }
		},
		envelope: {
			type: Object,
			default: function () { return {} }
		},
		config: {
			type: Object,
			default: function () { return {} }
		},
		translations: {
			type: Object
		}
	},
	data() {
		return {
			displayPDF: true,
			loading: true,
			showLoader: false,
			pdfViewerSrc: '',
			pdfViewerSrc2: '',
			msDocText: '',
			ud: {
				_ignoreRenderTemplate: true,
				_isForwarded: true,
				redirectTo: '',

				pdfMethod: { 0: true, 1: false },
				extract: { 0: true, 1: false },

				pdfTitle: 'Group signing - Agreement',
				pdfText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque fringilla maximus. Sed aliquet tempor tempor. Ut mauris felis, semper ac nulla et, tincidunt ornare ipsum. Pellentesque aliquam porttitor tortor, at placerat metus sagittis eu. Nullam laoreet lacus et massa pulvinar, consectetur vulputate risus imperdiet. Mauris lacus odio, sodales sit amet sagittis sed, fringilla at sem. Aliquam non maximus nisl. In vitae molestie neque. Proin scelerisque libero id tortor suscipit dictum.\n\nDonec vel porta nunc. Quisque libero lacus, laoreet quis magna sit amet, dapibus rhoncus magna. Fusce aliquam mi ut auctor malesuada. In vulputate erat eget feugiat condimentum. Morbi nec auctor ex, at sagittis lorem. Proin tempor blandit accumsan. Quisque vitae ipsum velit. Quisque sed dolor quis nunc varius cursus. Suspendisse rhoncus sodales erat nec porttitor. Pellentesque nec neque vitae nulla vulputate accumsan vitae non mauris. Suspendisse ac placerat arcu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',

				signGroups: [{
					signers:[{
						// firstName: 'j',
						// lastName: 'g',
						// email: 'jorge.guerra+signer@verified.eu',
						firstName: '',
						lastName: '',
						email: '',
					}],
					groups: ''
				}],
			},
			acroFields: [],
			drawAcrofieldsPDFRAF: null,
			pages: [],
			uploadedFiles: [],
			canGetSsnInfo: false,
			canSubmit: false,
			submitRAF: null,
			signed: false
		}
	},
	methods: {
		__config(key) {
			if (this.config[key])
				return this.config[key].value
			return null
		},
		async onCreated() {
			try {
				this.ud.redirectTo = this.__('redirect_url')

				// const sort = encodeURIComponent(JSON.stringify({
				// 	modified: -1
				// }))
				// const filters = encodeURIComponent(JSON.stringify({
				// 	"and": [
				// 		// {
				// 		// 	"field": "documents.signatures.length",
				// 		// 	"op": "eq",
				// 		// 	"value": 0
				// 		// },
				// 		{
				// 			"field": "completed",
				// 			"op": "ne",
				// 			"value": true
				// 		},
				// 		// {
				// 		// 	"field": "aborted",
				// 		// 	"op": "ne",
				// 		// 	"value": true
				// 		// },
				// 		// {
				// 		// 	"field": "published",
				// 		// 	"op": "eq",
				// 		// 	"value": false
				// 		// }
				// 	]
				// }))
				// const res = await remote.call({
				// 	path: `/query-new/envelopes?filters=${filters}&sort=${sort}&limit=10`,
				// 	method: 'GET'
				// })
				// console.log(res.data)

				// let res = await remote.call({
				// 	path: `/bisnode/company?regNumber=5568831233`,
				// 	method: "GET"
				// })
				// console.log(res)

				// let res = await bisnode.sweden.getCompanyInfo('5568831233', true)
				// let res = await bisnode.sweden.getCompanyCreditInfo('5568831233','se')
				// console.log(res)

				// let res = await this.remoteCall({
				// 	path: `/bankid-se/auth-session`,
        		// 	method: "POST",
				// 	body: {
				// 		ip: "127.0.0.1",
				// 		personalNumber: "8807296994"
				// 	}
				// })
				// console.log(res.data)

				// let res = await this.remoteCall({
				// 	// path: `/bisnode/company?regNumber=5568831233`,
				// 	path: `/bisnode/company?regNumber=5568831233&rawRes=1`,
        		// 	method: "GET"
				// })
				// console.log(res.data)

				// let res = await this.remoteCall({
				// 	path: "/trapets/companies/S1klXLnov/query",
				// 	method: "POST",
				// 	headers: {
				// 		"Content-Type": "application/json",
				// 	},
				// 	body:{
				// 		"ServiceScope": "BVBeneficialOwners",
				// 		"SubQueries": [{
				// 			 "Id": '5568831233',
				// 			 "Countries": ["SWE"],
				// 			 "ItemNumber": '5568831233',
				// 			//  "Name": 'Verified'
				// 		}]
				// 	}
				// })
				// console.log(res.data)
				let id_token = this.getUrlParameter('id_token')
				if(id_token) {
					console.log('id_token', id_token)
				}
				let code = this.getUrlParameter('code')
				if(code) {
					console.log('code', code)
					let res = await remote.call({
						path: `/secure/oidc/initiate/token?code${code}`,
						method: "GET"
					})
					console.log(res.data)
				}
			} catch (err) {
				console.log(err)
			}
			this.loading = false
		},
		async onMounted() {
		},

		onPagesData(pages){
			pages.forEach(p => {
				let allText = p.items.map(i=>{ return i.str }) // create an array with all p.items.str
				.filter(i=>!/^ ?$/.test(i)) // remove rows with "" and " "
				.join('\n') // join rows as \n lines for textarea
				p.allText = allText
			})
			console.log(pages)
			this.pages = JSON.parse(JSON.stringify(pages))
		},

		async submit() {
			this.showLoader = true
			try {
				let ud = this.ud

				const envelope = await envelopes.create('flow-demos-group-signing')
				const bearerToken = await envelope.getBearerToken('/flows/simple-public-action-flow')
				auth.setToken(bearerToken)
				await envelope.reflect()
				const docName = ud.pdfMethod[0] ? this.uploadedFiles[0].name : `Flow-demos - Sign Groups.pdf`
				let promises = [
					// Set template data
					envelope.firstTemplate().setUserData(ud),
					// Set document filename
					envelope.firstDocument().put({ name: docName })
				]

				// Set document tags
				let tags = []
				if (tags.length) {
					promises.push(envelope.firstDocument().setTags(tags))
				}
				
				let recipients = []
				let sign_groups = []
				for(let i = 0; i < ud.signGroups.length; i++) {
					let li = ud.signGroups[i]
					sign_groups.push({ id: i+1, groups: li.groups })
					for(let j = 0; j < li.signers.length; j++) {
						let r = li.signers[j]
						recipients.push({
							givenName: r.firstName,
							familyName: r.lastName,
							email: r.email,
							language: this.$root.locale,
							signingMethod: 'email',
							order: i+1,
							role: {
								action: 'sign',
								label: 'Signera',
								name: 'signer'
							}
						})
					}
				}

				promises.push(envelope.put({
					metadata: {
						sign_groups: sign_groups,

						override_mail_notify_signatory: {
							subject: "ns overriden - subject",
							headline: "ns overriden - headline",
							// "jorge.guerra@verified.eu": {
							// 	subject: "ns overriden {{recipient.givenName}} {{recipient.familyName}} recipient - subject",
							// 	headline: "ns overriden {{recipient.givenName}} {{recipient.familyName}} recipient - headline"
							// }
						},

						// override_mail_document_signed: {
						// 	subject: "ds overriden - subject",
						// 	headline: "ds overriden - headline",
						// 	"jorge.guerra@verified.eu": {
						// 		subject: "ds {{recipient.givenName}} {{recipient.familyName}} overriden recipient - subject",
						// 		headline: "ds {{recipient.givenName}} {{recipient.familyName}} overriden recipient - headline"
						// 	}
						// },

						// noDocumentSignedMails: true,
						// silentParties: [
						// 	// {
						// 	// 	givenName: 'J1',
						// 	// 	familyName: 'G1',
						// 	// 	email: 'jorge.guerra+silentsigned1@verified.eu',
						// 	// 	// emailTemplate: '/notify-reviewer.marko',
						// 	// 	secure: true,
						// 	// 	sms: true,
						// 	// 	telephone: '+46793322697'
						// 	// },
						// 	// {
						// 	// 	givenName: 'J2',
						// 	// 	familyName: 'G2',
						// 	// 	email: 'jorge.guerra+silentsigned2@verified.eu',
						// 	// 	// emailTemplate: '/../document-signed.marko',
						// 	// 	// emailHtml: '<html><head><title>EMAIL SUBJECT</title></head><body>EMAIL BODY</body></html>',
						// 	// 	secure: true,
						// 	// 	bank: 'se',
						// 	// 	ssn: 'txt.198807296994'
						// 	// },
						// 	{
						// 		givenName: 'J3',
						// 		familyName: 'G3',
						// 		email: 'jorge.guerra+silentsigned3@verified.eu',
						// 	},
						// ],
						// noDocumentRejectedMails: true,
						// silentPartiesRejected: [{
						// 	givenName: 'JR',
						// 	familyName: 'GR',
						// 	email: 'jorge.guerra+silentrejected@verified.eu',
						// 	// emailTemplate: '/document-rejected-custom.marko',
						// }],
						// noDocumentAbortedMails: true,
						// silentPartiesAborted: [{
						// 	givenName: 'JA',
						// 	familyName: 'GA',
						// 	email: 'jorge.guerra+silentaborted@verified.eu',
						// 	// emailTemplate: '/document-aborted-custom.marko',
						// }],
						envelopeConfig: 'https://dev-verified.eu/lib/iframes/envelope_vm.js'
					}
				}))

				for(let i = 0; i < recipients.length; i++) {
					let r = recipients[i]
					promises.push(envelope.addRecipient(r))
				}

				await Promise.all(promises)

				// Upload pdf
				if(ud.pdfMethod[0]) {
					if(ud.extract[0]) {
						await envelope.firstDocument().uploadPDF(docName, this.uploadedFiles[0])
					} else {
						await envelope.firstDocument().uploadPDF(docName, await this.getPdfFields())
					}
				} else {
					await envelope.firstDocument().uploadPDF(docName, await this.getFilledPDF())
				}

				// Publish
				await envelope.reflect()
				await envelope.publish()
				// Redirect
				this.signed = true
			} catch (e) {
				console.error(e)
			}
			this.showLoader = false
		},

		async remoteCall(opts) {
			if (!auth.token) throw new Error("must be authenticated with an access token before you can make calls to the api");
			let headers = opts.headers || {};
			headers["authorization"] = "JWT " + auth.token;
			if (auth.namespace) {
				headers["x-namespace"] = auth.namespace;
			}
			headers["content-type"] = "application/json";
			headers["accept"] = "application/json";
			try {
				let res = await axios({
					url: "/api" + opts.path,
					method: opts.method.toUpperCase(),
					headers: headers,
					data: opts.body,
					withCredentials: "same-origin",
					params: opts.params || {}
				});
				return res;
			}
			catch (err) {
				throw new Error(err);
			}
		},

		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = decodeURIComponent(sPageURL).split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || "").split("=");
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},

		async getFilledPDF() {
			const existingPDFBytes = await fetch('verified_logo.pdf').then(res => res.arrayBuffer())
			const pdfDoc = await PDFDocument.load(existingPDFBytes)

			const ud = this.ud

			let font = await pdfDoc.embedFont(StandardFonts.Helvetica)
			const pages = pdfDoc.getPages()
			function draw(page, vals) {
				const { width, height } = page.getSize()
				for(let i = 0; i < vals.length; i++) {
					let val = vals[i]
					if(val.t) {
						let t = val.t
						let draw_options =  { 
							x:( width*val.x/100), 
							y: height-(height*val.y/100), 
							size: height*(val.s || 0.013), 
							lineHeight: height*(val.s || 0.013)*(val.l || 100)/100,
							font: font 
						}
						if(val.w) {
							const textWidth = font.widthOfTextAtSize(val.t, draw_options.size)
							const maxWidth = width/100*val.w
							const diffScale = maxWidth/textWidth
							if(diffScale < 1) {
								draw_options.size *= diffScale
							}
						}
						if(val.fitwidth) {
							let fitwidth = width/100*val.fitwidth
							let linebreaks = []
							let lines = val.t.split('\n')
							for(let i = 0; i < lines.length; i++) {
								const line = lines[i]
								const words = line.match(/ *[^ ]+/g)
								let sentence = ''
								if(words && words.length) {
									for(let j = 0; j < words.length; j++) {
										const word = words[j]
										let tempsentence = sentence + word
										let tempTextWidth = font.widthOfTextAtSize(tempsentence, draw_options.size)
										if(tempTextWidth < fitwidth) {
											sentence = tempsentence
										} else {
											linebreaks.push(sentence)
											sentence = word.replace(/^ */,'')
										}
									}
								}
								linebreaks.push(sentence)
							}
							t = linebreaks.join('\n')
						}
						page.drawText(t, draw_options)
					} else if (val.rx && val.ry) {
						page.drawRectangle({ 
							x:( width*val.rx/100), 
							y: height-(height*val.ry/100), 
							width: height*(val.s || 0.01), 
							height: height*(val.s || 0.01), 
						})
					} else if (val.img) {
						const imgDims = val.img.scale(1)
						let draw_options = {
							x:( width*val.x/100), 
							y: height-(height*val.y/100), 
							width: imgDims.width,
							height: imgDims.height,
						}
						let diffScaleHeight = null
						if(val.h) {
							const maxHeight = height/100*val.h
							diffScaleHeight = maxHeight/imgDims.height
						}
						let diffScaleWidth = null
						if(val.w) {
							const maxWidth = width/100*val.w
							diffScaleWidth = maxWidth/imgDims.width
						}
						if(diffScaleWidth && diffScaleWidth < 1 && ( !diffScaleHeight || diffScaleHeight > diffScaleWidth ) ) {
							draw_options.width *= diffScaleWidth
							draw_options.height *= diffScaleWidth
						}
						if(diffScaleHeight && diffScaleHeight < 1 && ( !diffScaleWidth || diffScaleWidth > diffScaleHeight ) ) {
							draw_options.width *= diffScaleHeight
							draw_options.height *= diffScaleHeight
						}
						page.drawImage(val.img, draw_options)
					}
				}
			}
			
			let dp = [
				{ t: ud.pdfTitle, x: 12, y: 25, s: 0.03 },
				{ t: ud.pdfText, x: 12, y: 30, fitwidth: 76 },
			]
			draw(pages[0], dp)

			// DEV Only
			// const pdfDocDev = await PDFDocument.create()
			// let pdfPages = await pdfDocDev.copyPages(pdfDoc, [0])
			// pdfDocDev.addPage(pdfPages[0])
			// const pdfBytes = await pdfDocDev.save()

			// Production
			const pdfBytes = await pdfDoc.save()
			const pdfBlob = new Blob([pdfBytes], {
				type: "application/pdf"
			})
			if(this.displayPDF) {
				this.pdfViewerSrc = pdfBytes		// Uint8Array
				// this.pdfViewerSrc = pdfBlob			// Blob

				// if (document.getElementById('pdf-modified')) {
				// 	document.getElementById('pdf-modified').setAttribute(
				// 		'src',
				// 		URL.createObjectURL(pdfBlob)
				// 	)
				// }
			}

			return pdfBlob
		},

		async getPdfFields({init}){
			const existingPDFBytes = await this.uploadedFiles[0].arrayBuffer()
			const pdfDoc = await PDFDocument.load(existingPDFBytes)

			const form = pdfDoc.getForm()
			const all_fields = form.acroForm.getAllFields()
			const fields = all_fields.map((f) => {
				let type = '?'
				switch (true) {
					case f[0] instanceof PDFAcroText: type = 'PDFAcroText'; break;
					case f[0] instanceof PDFAcroCheckBox: type = 'PDFAcroCheckBox'; break;
					case f[0] instanceof PDFAcroRadioButton: type = 'PDFAcroRadioButton'; break;
					case f[0] instanceof PDFAcroComboBox: type = 'PDFAcroComboBox'; break;
					case f[0] instanceof PDFAcroListBox: type = 'PDFAcroListBox'; break;
					default: break;
				}
				let li = {
					name: f[0].getFullyQualifiedName(),
					type: type,
				}
				if(li.type == 'PDFAcroText') {
					Object.assign(li, {
						value: form.getTextField(li.name).getText()
					})
				} else if (li.type == 'PDFAcroCheckBox') {
					Object.assign(li, {
						checked: {
							value: form.getCheckBox(li.name).isChecked()
						}
					})
				} else if (li.type == 'PDFAcroRadioButton') {
					const field = form.getRadioGroup(li.name)
					const selected = field.getSelected()
					const options = field.getOptions()
					let radioValues = {}
					let radioOptions = {}
					options.forEach((o,i)=>{
						radioValues[i] = o == selected ? true: false
						radioOptions[i] = { label: o }
					})
					Object.assign(li, {
						value: radioValues, // select()
						options: radioOptions,
					})
				} else if (li.type == 'PDFAcroComboBox') {
					const field = form.getDropdown(li.name)
					const selected = field.getSelected()
					const value = selected && selected[0] ? selected[0]: ''
					Object.assign(li, {
						value: value,
						options: field.getOptions() // setOptions()
					})
				} else if (li.type == 'PDFAcroListBox') {
					const field = form.getOptionList(li.name)
					const selected = field.getSelected()
					const value = selected && selected[0] ? selected[0]: ''
					Object.assign(li, {
						value: value,
						options: field.getOptions() // setOptions()
					})
				}
				return li
			})
			// console.log(form)
			// console.log(all_fields)
			// console.log(JSON.parse(JSON.stringify(fields)))

			if(init) {
				this.acroFields = fields
				cancelAnimationFrame(this['drawAcrofieldsPDFRAF'])
			} else {
				this.acroFields.forEach((li,i)=>{
					if(li.type == 'PDFAcroText') {
						form.getTextField(li.name).setText(li.value)
					} 
					else if (li.type == 'PDFAcroCheckBox') {
						form.getCheckBox(li.name)[li.checked.value ? 'check': 'uncheck']()
					} 
					else if (li.type == 'PDFAcroRadioButton') {
						const field = form.getRadioGroup(li.name)
						let selected = ''
						const options = field.getOptions()
						for(let item_i in li.value) {
							let item = li.value[item_i]
							if(item) {
								selected = options[item_i]
							}
						}
						if(selected) {
							field.select(selected)
						}
					} 
					else if (li.type == 'PDFAcroComboBox') {
						const field = form.getDropdown(li.name)
						const value = li.value || ''
						if(value) {
							field.select(value)
						}
					} 
					else if (li.type == 'PDFAcroListBox') {
						const field = form.getOptionList(li.name)
						const value = li.value || ''
						if(value) {
							field.select(value)
						}
					}
				})
			}

			// DEV Only
			// const pdfDocDev = await PDFDocument.create()
			// let pdfPages = await pdfDocDev.copyPages(pdfDoc, [0])
			// pdfDocDev.addPage(pdfPages[0])
			// const pdfBytes = await pdfDocDev.save()

			// Production
			const pdfBytes = await pdfDoc.save()
			const pdfBlob = new Blob([pdfBytes], {
				type: "application/pdf"
			})
			if(this.displayPDF) {
				if (document.getElementById('pdf-modified')) {
					document.getElementById('pdf-modified').setAttribute(
						'src',
						URL.createObjectURL(pdfBlob)
					)
				}
			}

			return pdfBlob
		},

		async getMSText(){
			try {
				let self = this
				this.msDocText = ''
				var reader = new FileReader();
				reader.onload = function() {
					var zip = new jszip(reader.result);
					console.log(docxtemplater)
					var doc = new docxtemplater().loadZip(zip);
					console.log(doc)
					var text = doc.getFullText();
					console.log(text)
					self.msDocText = text
				};
				reader.readAsBinaryString(this.uploadedFiles[0]);
			} catch (e) {
				console.log(e)
			}
		},

		chooseFileFiles() {
			this.$refs.uploadFileInput.click()
		},
		updateFileFiles(e) {
			this.uploadedFiles = []
			for(let i = 0; i < e.target.files.length; i++) 
				this.uploadedFiles.push(e.target.files[i])
			this.pdfViewerSrc2 = this.uploadedFiles[0] || ''
			this.getPdfFields({init:true})
			this.getMSText()
		},

		validateSubmit() {
			let self = this
			cancelAnimationFrame(this['submitRAF'])
			this['submitRAF'] = requestAnimationFrame(() => {
				let firstFormValid = self.$refs.firstForm ? self.$refs.firstForm.checkValidity() : false
				let uploadValid = (self.ud.pdfMethod[0] && self.uploadedFiles.length) || self.ud.pdfMethod[1]
				let valid = firstFormValid && uploadValid
				self.canSubmit = valid
			})
		},

		drawAcrofieldsPDF(){
			let self = this
			cancelAnimationFrame(this['drawAcrofieldsPDFRAF'])
			this['drawAcrofieldsPDFRAF'] = requestAnimationFrame(() => {
				self.getPdfFields({init:false})
			})
		},

		drawPDF(){
			if(this.displayPDF) {
				this.getFilledPDF()
			}
		},

		saveAs(){
			let words = ['Grupp signering']
			words.push(new Date().toISOString().replace('T',' ').replace(/\..*/g, ''))
			return words.join(' - ')+'.json'
		}

	},
	created() { this.onCreated() },
	mounted() { this.onMounted() },
	watch: {
		displayPDF(){
			this.drawPDF()
		},
		ud: { handler() { 
			this.validateSubmit()
			this.drawPDF()
		}, deep: true },
		uploadedFiles: { handler() { 
			this.validateSubmit()
		}, deep: true },
		acroFields: { handler() { 
			this.drawAcrofieldsPDF({init: false})
		}, deep: true },
	}
}