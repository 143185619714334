import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Portal from '../components/portal/portal'
import GroupSigning from '../components/form-group-signing/form-group-signing'
import { auth, envelopes } from '@verified/libvf'

export default {
	name: 'app',
	data() {
		return {
			sheetname: "flow-demos",
			lang: "sv_SE",
			loading: true,
			exceptions: [],
			hidePortalContent: true,
			contentOpacity: 1,
			formOpacity: 1,
			step: 0,
			steps: [],
			envelope: undefined,
			userdata: undefined,
			searchResult: undefined,
			ud: {
				form: '',
				greeting: null,
				healthContacts: [],
				propertyContacts: [],
				company: {}
			}
		}
	},
	watch: {
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		async onCreated() {
			Vue.use(VueCookies)
			Vue.use(Portal)
			Vue.use(GroupSigning)
			
			this.$root.locale = 'sv_SE'
			this.setIso("sv_SE")
			
			//Get sheets data
			try {
				this.loadingLists = true
				// Get sheet data
				if(this.sheetname) {
					const [translations, portal, config] = await Promise.all([
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/translations'),
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/portal-translations'),
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/config'),
					])
			
					this.translations = { ...translations.data, ...portal.data }
					this.configuration = config.data

					this.mergeLocale(this.translations)

					this.mergeLocale({
						"validation.checkbox": {
							sv_SE: "Krävs",
							nb_NO: "Påkrevd",
							sv_SE: "Checkbox must be select"
						}
					})
				}

				//Set page favicon and title
				if(this.__config('favicon'))
					document.getElementById('favicon').href = this.__config('favicon')
				if(this.__config('title'))
					document.title = this.__config('title')
				
				//Set CSS colors
				if(this.__config('primary_color')) {
					document.documentElement.style.setProperty('--primary', this.__config('primary_color'))
					document.documentElement.style.setProperty('--light', this.colorLuminance(this.__config('primary_color'), 0.4))
					document.documentElement.style.setProperty('--highlight', this.colorLuminance(this.__config('primary_color'), 0.12))
					document.documentElement.style.setProperty('--dark', this.colorLuminance(this.__config('primary_color'), -0.25))
					document.documentElement.style.setProperty('--darker', this.colorLuminance(this.__config('primary_color'), -0.35))

					document.documentElement.style.setProperty('--secondary', this.__config('secondary_color'))
					document.documentElement.style.setProperty('--navbar', this.__config('navbar_color'))
					
				}
				
			} catch(e) {
				console.error(e)
				this.exceptions.push(new Error('Unable to get sheet data. Try refreshing your browser.'))
			}
			
			this.loading = false
		},
		async onMounted() {},
		async init(role) {
			this.role = role
			console.log(this.role)

			if(this.role === "editor") {
				try {
					this.envelope = await envelopes.getFromUrl()
					let data = await this.envelope.firstTemplate().getUserData()
					this.ud.form = data.form
					console.log("dgfkjsdg: " + this.ud.form)
					this.ud = {...this.ud, ...data}
					this.userdata = this.ud
					this.role = auth.role
					console.log(this.role)

					if (this.ud.finalSubmit) {
						if (localStorage.getItem(this.envelope.data.id)) {
							this.signUrl = localStorage.getItem(this.envelope.data.id);
							console.log(this.signUrl);
							window.location.href = this.signUrl;
							this.loading = false;
							this.$forceUpdate();
						} else {
							this.alreadyFilled = true;
							this.loading = false;
						}
					} else {
						this.fadeToStep(1)
					}
				}
				catch (e) {
					console.log(e)
					$cookies.remove("user")
					$cookies.remove("token")
					this.loading = false
				}
			} else {
				this.hidePortalContent = false
			}
		},
		async formSelected(value) {
			//Form or URL (can't have space)
			if(value && value.indexOf(" ") !== -1) {
				this.hidePortalContent = true
				this.ud.form = value.split(" ")[1]

				if(this.ud.form === 'search')
					this.fadeToStep(20)
				else if(this.ud.form === 'select')
					this.fadeToStep(0)
				else
					this.fadeToStep(1)
			} else if(value) {
				try {
					let token = $cookies.get("token")
					let user = $cookies.get("user")
					let link = value// + "?access_token=" + token + "&c=" + user.namespace.split('/').pop()
					window.open(link)
				} catch(e) {
					console.error(e)
					this.exceptions.push(new Error('An error occured during get bearer token'))
				}
			}
		},
		fadeToStep(step) {
			this.formOpacity = 0
			setTimeout(() => {
				this.step = step
				if(step === 0)
					this.hidePortalContent = false
				setTimeout(() => {
					this.contentOpacity = this.formOpacity = 1
				}, 10)
			}, 500)
		},
		companySelected(company) {
			if(company) {
				this.ud.company = company
				
				// Init signers
				if(company.roles && company.roles.length) {
					this.signers.clients = company.roles.filter((el) => {
						return el.BusinessPosition && (el.BusinessPosition.BusinessPositionCode === 'DL' || el.BusinessPosition.BusinessPositionCode === 'SF')
					})
					
					this.signers.clients.forEach((el) => {
						if(el.BusinessPosition && el.Person && el.Person.Name && el.Person.Name.LastName && el.Person.Name.FirstName) {
							let name = el.Person.Name.LastName.toLowerCase() + ', ' + el.Person.Name.FirstName.toLowerCase() + ' (' + el.BusinessPosition.Description + ')'
							
							//Capitalize
							this.signers.clientsDd.push(name.replace(/(?:^|\s)\S/g, (a) => { return a.toUpperCase() }))
						}
					})
				}
			}
		},
		setLanguage (value){
			this.lang = value
			this.$root.locale = this.lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		}
	},
	created() { this.onCreated() },
	mounted() { this.onMounted() },
}