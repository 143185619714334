import Vue from 'vue'
import App from './page/App.vue'
import idcontrol from './components/idcontrol'
import feedback from './components/feedback'
import veform from '@verified/veform'
// import veform from '../../veform'

Vue.config.productionTip = false

import "./page/style.scss"

idcontrol.install(Vue)
feedback.install(Vue)
Vue.use(veform)

veform.addCustomValidation('tennr', (value, constraint) => {
    let status = false;
    if (/^[0-9]{6}-?[0-9]{4}$/.test(value)) {
        status = true;
    }
    return { status: status, key: "validation.tennr" }
})

new Vue({
    render: h => h(App)
}).$mount('#app')